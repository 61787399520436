import React from "react";
import { IoPerson } from "@react-icons/all-files/io5/IoPerson";
import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt";
import { classNames } from "repoV2/utils/common/render/classNames";
import { GiHamburgerMenu } from "@react-icons/all-files/gi/GiHamburgerMenu";
import { IoClose } from "@react-icons/all-files/io5/IoClose";
import { BsCaretDownFill as DownIcon } from "@react-icons/all-files/bs/BsCaretDownFill";
import { BsCaretUpFill as UpIcon } from "@react-icons/all-files/bs/BsCaretUpFill";
import { PoweredByExly } from "repoV2/features/CreatorPages/modules/PoweredByExly";
import { ButtonV2 } from "@Modules/common/ButtonV2";
import { useScrollToSectionOnMount } from "@Utils/hooks/rendering";
import styles from "./navbarYoga.module.scss";
import { INavbar } from "../../INavbar";
import { Notification } from "../../components/Notification";
import { Account } from "../../components/Account";
import { NAVBAR_ITEM_KEYS } from "../../data/getNavbarItems";
import { LinkItem, NavLink } from "../../components/NavLinks";

const NavbarYoga = ({
    navbarItems,
    notificationProps,
    accountProps,
    hideMobHamMenu,
    onMobHamMenuClick,
    isMobHamMenuOpen,
    isOfferingsDropdownOpen,
    rootElement,
}: INavbar.IChildProps): JSX.Element => {
    // Automatically scroll to the section mentioned in url after "#" after 1200 ms.
    // why 1200 ms?? - to wait for page to load
    useScrollToSectionOnMount(1200);
    const EventListingsDropdown = navbarItems.eventListings.showChildren ? (
        <div
            className={classNames(
                styles.eventListingsDropdownContainer,
                isOfferingsDropdownOpen && styles.open
            )}
        >
            {navbarItems.eventListings.children.map(navbarItem => (
                <a
                    key={navbarItem.content.toString()}
                    className={classNames(
                        styles.navbarItem,
                        styles.eventListingsDropdownItem,
                        isOfferingsDropdownOpen && styles.open
                    )}
                    {...navbarItem.props}
                >
                    {navbarItem.content}
                </a>
            ))}
        </div>
    ) : null;

    const showNavItems = [
        "team",
        "testimonials",
        "gallery",
        "blog",
    ] as Array<INavbar.INavbarItemKeys>;

    const DesktopMobCommonItems = () => (
        <>
            <NavLink
                showItems={showNavItems}
                navbarItems={navbarItems}
                classes={styles.navbarItem}
            />

            {navbarItems.externalLinks.map(item => (
                <LinkItem
                    // item.content is string for externalLinks
                    key={item.content.toString()}
                    content={item.content}
                    className={styles.navbarItem}
                    {...item.props}
                />
            ))}
        </>
    );

    const AccountAndNotification = (
        <>
            <Notification
                {...notificationProps}
                notificationIconColorScheme={styles.colourScheme1}
            />
            <div className="position-relative">
                <Account
                    {...accountProps}
                    accountIconColorScheme={styles.colourScheme1}
                />
            </div>
        </>
    );

    return (
        <div className={styles.root} ref={rootElement}>
            <a
                className={classNames(styles.homeButton, styles.navbarItem)}
                {...navbarItems.home.props}
            >
                {navbarItems.home.content}
            </a>
            <div className={classNames(styles.desktop, styles.rightContainer)}>
                <a className={styles.navbarItem} {...navbarItems.about.props}>
                    {navbarItems.about.content}
                </a>
                {navbarItems.eventListings.showItem ? (
                    <div
                        className={classNames(
                            styles.navbarItem,
                            styles.eventListingsButton
                        )}
                        {...navbarItems.eventListings.props}
                    >
                        {navbarItems.eventListings.content}
                        <div className="w-100 d-flex justify-content-center position-absolute">
                            {EventListingsDropdown}
                        </div>
                    </div>
                ) : null}

                <DesktopMobCommonItems />

                {AccountAndNotification}
                {navbarItems.login.showItem ? (
                    <a
                        className={classNames(
                            styles.navbarItem,
                            styles.coloured,
                            styles.colourScheme1,
                            styles.hoverable,
                            styles.button
                        )}
                        {...navbarItems.login.props}
                    >
                        {navbarItems.login.content}
                    </a>
                ) : null}
                {navbarItems.getInTouch.showItem ? (
                    <ButtonV2
                        applyTheme
                        className={classNames(
                            styles.navbarItem,
                            styles.coloured,
                            styles.colourScheme2,
                            styles.button
                        )}
                        {...navbarItems.getInTouch.props}
                    >
                        {navbarItems.getInTouch.content}
                    </ButtonV2>
                ) : null}
            </div>

            <div className={classNames(styles.mobile, styles.rightContainer)}>
                {isMobHamMenuOpen ? null : AccountAndNotification}
                {navbarItems.getInTouch.showItem ? (
                    <div
                        className={classNames(styles.button)}
                        {...navbarItems.getInTouch.props}
                    >
                        <FaPhoneAlt />
                    </div>
                ) : null}
                {navbarItems.login.showItem ? (
                    <div
                        className={classNames(styles.button)}
                        {...navbarItems.login.props}
                    >
                        <IoPerson />
                    </div>
                ) : null}
                {!hideMobHamMenu ? (
                    <div>
                        <div
                            className={classNames(
                                styles.mobHamMenuButton,
                                styles.colourScheme2
                            )}
                            onClick={onMobHamMenuClick}
                        >
                            {isMobHamMenuOpen ? (
                                <IoClose size={20} />
                            ) : (
                                <GiHamburgerMenu size={20} />
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
            <div
                className={classNames(
                    styles.mobile,
                    styles.mobileRoot,
                    isMobHamMenuOpen && styles.open
                )}
            >
                <a className={styles.navbarItem} {...navbarItems.about.props}>
                    {navbarItems.about.content}
                </a>
                {navbarItems.eventListings.showItem ? (
                    <div
                        className={classNames(styles.navbarItem)}
                        {...navbarItems.eventListings.props}
                    >
                        <div className={styles.eventListingsButton}>
                            {navbarItems.eventListings.content}
                            {navbarItems.eventListings.showChildren ? (
                                <>
                                    &nbsp;&nbsp;
                                    {isOfferingsDropdownOpen ? (
                                        <UpIcon />
                                    ) : (
                                        <DownIcon />
                                    )}
                                </>
                            ) : null}
                        </div>
                        {EventListingsDropdown}
                    </div>
                ) : null}
                <DesktopMobCommonItems />
                <hr />
                {(
                    [
                        NAVBAR_ITEM_KEYS.myClasses,
                        NAVBAR_ITEM_KEYS.myMeetings,
                        NAVBAR_ITEM_KEYS.purchaseHistory,
                    ] as Array<INavbar.INavbarItemKeys>
                ).map(navbarItemKey => {
                    const navbarItem = navbarItems[navbarItemKey];
                    return navbarItem.showItem ? (
                        <a
                            key={navbarItemKey}
                            className={styles.navbarItem}
                            {...navbarItem.props}
                        >
                            {navbarItem.content}
                        </a>
                    ) : null;
                })}
                {navbarItems.getInTouch.showItem ? (
                    <div
                        className={classNames(
                            styles.colourScheme2,
                            styles.button
                        )}
                        {...navbarItems.getInTouch.props}
                    >
                        {navbarItems.getInTouch.content}
                    </div>
                ) : null}
                {(["login", "logout"] as Array<INavbar.INavbarItemKeys>).map(
                    navbarItemKey => {
                        const navbarItem = navbarItems[navbarItemKey];
                        return navbarItem.showItem ? (
                            <a
                                key={navbarItemKey}
                                className={classNames(
                                    styles.colourScheme1,
                                    styles.button
                                )}
                                {...navbarItem.props}
                            >
                                {navbarItem.content}
                            </a>
                        ) : null;
                    }
                )}
                <div style={{ flexGrow: 1 }} />
                <hr />
                <PoweredByExly showCopyright />
            </div>
        </div>
    );
};

export default NavbarYoga;
